import React, { useState } from 'react';
import { Tabs } from 'antd';
import "./Collection.css";
import { Button, Image, Space } from 'antd';
export default function Collection() {
  const [random, setRandom] = useState();
  const tabsGranite = [
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Falaska-gold-bright.jpg?alt=media&token=64f710cf-35ad-42f7-92f0-5550476d3af9",
      overlayText: "Alaska Gold Bright"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fcrystal-black.jpg?alt=media&token=d73edf51-d37f-4683-80b6-154754103ddf ",
      overlayText: "Crystal Black"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fjhansi-red.jpg?alt=media&token=5e5217ff-9653-41d2-867e-782a68314e67",
      overlayText: "Jhansi Red"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Falaska-gold.jpg?alt=media&token=f8a0732f-d5b0-412f-9b2e-fdc6d6d7d648",
      overlayText: "Alaska Gold"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fcrystal-blue.jpg?alt=media&token=c238b010-88b2-410f-90fe-88aea0cf0593",
      overlayText: "Crystal Blue"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Flakha-red.jpg?alt=media&token=a98faa06-bcba-4648-a866-a597d36cb419",
      overlayText: "Lakha Red"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Falaska-white.jpg?alt=media&token=fb364b99-d4ac-4b27-8d3a-b8b2c28af53f",
      overlayText: "Alaska White"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fcrystal-brown.jpg?alt=media&token=b0106cfb-72f5-4cb1-a7fa-bbb3fe64e9bc",
      overlayText: "Crystal Brown"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fmoon-white.jpg?alt=media&token=c07e7eed-9e04-4cbf-bcf6-96b72379ea7d",
      overlayText: "Moon White"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Falaska-white-flex.jpg?alt=media&token=11905b5c-2e21-484e-bd9c-07f7c06c7657",
      overlayText: "Alaska White Flex"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fcrystal-pink.jpg?alt=media&token=7e90a0ae-0cd9-4f4b-bd54-81fe998ca561",
      overlayText: "Crystal Pink"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fmulti-red.jpg?alt=media&token=8b43b21d-104f-43f3-8dfd-63147eaca56d",
      overlayText: "Multi Red"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fantique-green.jpg?alt=media&token=cd792961-96b2-4610-8270-029221c44aff",
      overlayText: "Antique Green"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fcrystal-red.jpg?alt=media&token=ee93aaae-4178-47bb-8afd-bfd16cd7451d",
      overlayText: "Crystal Red"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fpink-pearl.jpg?alt=media&token=fa00225b-547a-4b98-9cb7-8247fad64438",
      overlayText: "Pink Pearl"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fblack-marquina.jpg?alt=media&token=b0d51461-b3d8-4a27-9164-43e67b6284a2",
      overlayText: "Black Marquina"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fcrystal-yellow.jpg?alt=media&token=5dee8044-58c8-4c2a-8b11-46a437e83ae2",
      overlayText: "Crystal Yellow"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fr-black.jpg?alt=media&token=efabf699-07a5-453a-8595-384d88538a56",
      overlayText: "R Black"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fblack-zebra.jpg?alt=media&token=5ea2a49b-f49d-4357-a12e-8055a78b1ba6",
      overlayText: "Black Zebra"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fdesert-pink.jpg?alt=media&token=d88591ef-739e-4e6a-8676-613c16080a32",
      overlayText: "Desert Pink"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Froyal-brown.jpg?alt=media&token=f743e3a4-fdd9-4d4a-9d50-e2431c506fd3",
      overlayText: "Royal Brown"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fblue-dunes.jpg?alt=media&token=51a1808b-79aa-4006-96b0-0175d547a222",
      overlayText: "Blue Dunes"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Ffantasy-black.jpg?alt=media&token=96476456-c7ad-4006-b1a1-8f0de73db6ee",
      overlayText: "Fantasy Black"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fruby-red.jpg?alt=media&token=5dd10348-1457-44b9-bd61-c53830dfaf34",
      overlayText: "Ruby Red"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fblue-dunes-premium.jpg?alt=media&token=c9c747d3-26d7-4824-ae4e-157cf0066aba",
      overlayText: "Blue Dunes Premium"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Ffire-red.jpg?alt=media&token=55de0660-d1b4-4b22-9ab8-0a89e0433ce0",
      overlayText: "Fire Red"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fsavannah-white.jpg?alt=media&token=ed3c8475-1410-4b30-b2b7-3c67af810be7",
      overlayText: "Savannah White"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fblue-night.jpg?alt=media&token=e881eec2-27a0-44fa-991a-1c0bceac767f",
      overlayText: "Blue Night"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Ffish-black.jpg?alt=media&token=efb6e90c-9c6c-49bb-badf-46ffdb83edbf",
      overlayText: "Fish Black  "
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fabsolute-black.jpg?alt=media&token=ac4fd0bc-c6cb-4e48-adbb-2fb4e2c66cf7",
      overlayText: "Absolute Black"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fcoin-black.jpg?alt=media&token=07d2d37b-9e57-4354-aca5-0470b95509f3",
      overlayText: "Coin Black"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fgreen-pearl.jpg?alt=media&token=0866b691-ed25-4281-9fb6-092ead324060",
      overlayText: "Green Pearl"
    },
  ]
  const tabsMarbles = [
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FMarbles%2Fbrown-marquina.jpg?alt=media&token=edf6c69a-eb50-4a80-873b-4a7fda973301",
      overlayText: "Brown Marquina"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FMarbles%2Fgreen-marble.jpg?alt=media&token=aee95c8a-d854-43b5-bc45-66aefb4fbc07",
      overlayText: "Green Marble"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FMarbles%2Fpanda-white.jpg?alt=media&token=7245490b-8dda-4314-a6a1-e949b300b6d1",
      overlayText: "Panda White"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FMarbles%2Fcappuccino.jpg?alt=media&token=3d4fea3e-d701-4e2a-a9a9-fe6aed207ff1",
      overlayText: "Cappuccino"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FMarbles%2Fcarrara.jpg?alt=media&token=64de87ab-97d2-48fa-bbc6-366961d7933e",
      overlayText: "Carrara"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FMarbles%2Fmatrix.jpg?alt=media&token=8f179736-c5a7-461d-b1db-a88c6e9ae82c",
      overlayText: "Matrix"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FMarbles%2Fdesert-brown.jpg?alt=media&token=c1aab008-576a-4543-ac64-5539ed0b5266 ",
      overlayText: "Desert Brown"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FMarbles%2Fonyx.jpg?alt=media&token=50a6e260-0812-4680-951b-88a4cc874510",
      overlayText: "Onyx"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FMarbles%2Fnizarna.jpg?alt=media&token=300af590-638e-4cc0-9a58-80fe02ef466f",
      overlayText: "nizarna"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FMarbles%2Fdolsey.jpg?alt=media&token=1f50dad7-95d2-4aa8-81b4-7895b54aa8de",
      overlayText: "Dolsey"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FMarbles%2Fpalissandro.jpg?alt=media&token=ca651b3d-7107-4051-9378-2e204b74c661",
      overlayText: "Palissandro"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FMarbles%2Fpink.jpg?alt=media&token=d1c50f46-21a3-421f-a4f5-f0173a9928fd",
      overlayText: "Pink"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FMarbles%2Fgrafitto.jpg?alt=media&token=23ec7de6-930b-46b6-a83e-b6b0d3c240d2",
      overlayText: "Grafitto"
    },
  ]

  const items = [
    {
      key: '1',
      label: <><h2 className='collection-tab-heading'>Granite</h2></>,
      children:
        <>
          <div className="container tab-content-img">
            <div className="row">

              {tabsGranite.map((tabsGranite, index) => (
                <div className="col-lg-4 col-md-6 col-sm-12 p-0" >
                  <div id='image-text'>
                    <Image
                      // width={200}
                      className='marbles-images'
                      src={tabsGranite.images}
                      placeholder={
                        <Image
                          preview={false}
                          src={tabsGranite.images}
                          width={200}
                        />
                      
                      }
                      
                    />
                      <div className="image-title">{tabsGranite.overlayText}</div>
                    {/* <img src={tabsGranite.images} alt="" />
                    <span className='overlay-text'>{tabsGranite.overlayText}</span> */}
                  </div>
                </div>

              ))}
            </div>

          </div>
        </>
    },
    {
      key: '2',
      label: <><h2 className='collection-tab-heading'>Marbles</h2></>,
      children:
        <>

          <div className="container tab-content-img">
            <div className="row">

              {tabsMarbles.map((tabsMarbles, index) => (
                <div className="col-lg-4 col-md-6 col-sm-12 p-0" >
                  <div id='image-text'>
                    <Image
                      // width={200}
                      src={tabsMarbles.images}
                      className='marbles-images'
                      placeholder={
                        <Image
                          preview={false}
                          src={tabsMarbles.images}
                          width={200}
                        />
                      }
                    />
                    <div className="image-title">{tabsMarbles.overlayText}</div>
                    {/* <img  alt="" /> */}
                    {/* <span className='overlay-text'>{tabsMarbles.overlayText}</span> */}
                  </div>
                </div>

              ))}
            </div>

          </div>
        </>,
    },

  ];
  const onChange = (key) => {
    console.log(key);
  };

  return (
    <>

      <section id='aboutus-top-img'>
        <div className='top-banner'>
          <div className='overlay-theme'></div>
          <img src="https://images.unsplash.com/photo-1539141278536-63a0f98360a7?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" style={{ width: "100%" }} alt="" />
        </div>
        <div className='overlay-text'>
          <h1>Our Collections</h1>
        </div>
          
      </section>
      <br /><br />
      <section>
        <div className='collection-tabs container'>
          <Tabs defaultActiveKey="1" className='tabs' items={items} onChange={onChange} />
        </div>
      </section>
      <br /><br />
    </>
  )
}
