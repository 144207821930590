import React from 'react'
import homeImg1 from "./media/1707893273793.jpg"
import homeImg1_mob from "./media/1707895239588.jpg"
import homeImg2 from "./media/home-img2.jpg"
import homeImg3 from "./media/home-img3.jpg"
import stoneimg1 from "./media/marbleimg1.jpg"
import "./Home.css"
import { Link } from 'react-router-dom'



const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // adds smooth scrolling
  });
};
const scrollToBottom = () => {
  // Scroll to the bottom without any smooth scrolling effect
  document.body.scrollTop = document.body.scrollHeight;
  document.documentElement.scrollTop = document.documentElement.scrollHeight;
};

export default function Home() {


  return (
    <>
      <div style={{ position: "relative" }}>

        <div className="overlay"></div>
        <div className='homeImage1 homeimg_mobile'>
          <img src={homeImg1_mob} alt="Logo" />
        </div>
        <div className='homeImage1 homeimg_laptop'>
          <img src={homeImg1} alt="Logo" />
        </div>
        
      </div>
      <div className="slite-spacing"></div>
      <section className='homeSection1'>
        <div className='container home1'>
          <div className='row stoneInfoBox'>
            <div className='col-lg-6 col-md-6 col-sm-12 section1Part1'>
              <img className="roomImage mt-3" src={homeImg2} alt="Logo" />
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 mt-5 section1Part2'>
              <div className='row roomDescription'>
                <h1 className='homeTitle'>Our Granite</h1>
                <span className='titleunderline'>&nbsp;</span>
                <p className='pt-3'>Immerse yourself in the world of extravagance with our captivating granite collection, where strength meets beauty in a harmonious blend. Absolute Black granite, with its deep, lustrous black surface, exudes mystery and modernity, bringing a bold statement to any space. Kashmir White granite, with its intricate blend of whites and greys, creates a soft and soothing ambiance, combining tranquility with enduring strength. Blue Pearl granite, adorned with stunning blue and silver flecks on a dark background, adds a touch of celestial drama, making a lasting impact on interiors. Our granite slabs are not just a testament to durability but also a celebration of strength and elegance, ensuring your walls exude a timeless and powerful aesthetic.</p>
              </div>
              <div className='row roomStonesUsed m-auto'>
           
                <div className="col-lg-12 home-btn-set" >
                  <Link className='homeButton1' to="/collection" onClick={scrollToTop}>Explore Now</Link>
                  <Link className='homeButton2' onClick={scrollToBottom}>Enquire Now</Link>
                </div>


                {/* </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className='container home2 mt-5'>
          <div className='row stoneInfoBox'>
            <div className='col-lg-6 col-md-6 col-sm-12 section1Part1 mobile'>
              <img className="roomImage mt-3" src={homeImg3} alt="Logo" />
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 mt-5 section1Part2'>
              <div className='row roomDescription'>
                <h1 className='homeTitle'>Our Marbles</h1>
                <span className='titleunderline'>&nbsp;</span>
                <p className='pt-3'>Step into a world of timeless elegance with our exquisite marble collection. Renowned for its unparalleled beauty, marble is a testament to nature's artistry, offering a symphony of colors and patterns that transform any space into a masterpiece. Carrara marble, with its classic white backdrop and delicate grey veins, speaks of purity and sophistication, while Calacatta marble unleashes opulence with bold, sweeping veining on a pristine canvas. Statuario marble, with its dramatic, bold veins and pure white surface, adds a touch of luxury and exclusivity. Each marble slab is a work of art, promising durability alongside a timeless aesthetic that transcends trends, making it the perfect choice for elevating your walls and floors into statements of refined elegance.</p>
              </div>
              <div className='row roomStonesUsed m-auto'>
               

                <div className='col-lg-12 home-btn-set'>
                  <Link className='homeButton1' to="/collection" onClick={scrollToTop}>Explore Now</Link>
                  <Link className='homeButton2' onClick={scrollToBottom}>Enquire Now</Link>
                </div>
              </div>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 section1Part1 laptop'>
              <img className="roomImage mt-3" src={homeImg3} alt="Logo" />
            </div>
          </div>
        </div>

        <div className='sectional-padding'></div>
      
      </section>
      <div className="slite-spacing"></div>
    </>
  )
}
