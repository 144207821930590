import React from 'react';
import "./About.css";
// import "../GlobalCSS.css"
import { Card, Avatar } from 'antd';
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import img1 from "./logo.f4e7e3c2.png";
import cardData from './AboutCardContent';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import img2 from "./owner.cb11ea88.png"
// import img3 from "./1 (1).svg"

const { Meta } = Card;



export default function About() {
  const imageUrls = [
    { images: "https://gurudev-marbles.web.app/images/factory/1.svg" },
    { images: 'https://gurudev-marbles.web.app/images/factory/2.svg' },
    { images: 'https://gurudev-marbles.web.app/images/factory/3.svg' },
    { images: 'https://gurudev-marbles.web.app/images/factory/4.svg' },
    { images: 'https://gurudev-marbles.web.app/images/factory/5.svg' },
    // Add more URLs as needed
  ];
  return (
    <>
      <main>
        <section id='aboutus-top-img'>
          <div className='top-banner'>
            <div className='overlay-theme'></div>
            <img src="https://plus.unsplash.com/premium_photo-1675805903540-4599c4cd440b?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" style={{ width: "100%" }} alt="" />
          </div>
          <div className='overlay-text'>
            <h1>About Gurudev Marble</h1>
          </div>
        </section>
        <section id='About-us-hero'>
          <div className="section-spacing"></div>
          <div className='about-text container'>
            <h2 className='Section-title'><span>A</span>bout Us</h2>
            <p style={{ fontWeight: "500" }}>Gurudev Marble India Pvt. Ltd. was founded in 2010 with the goal of bringing the world's most exquisite marble collection to India. We offer a wide range of natural stones in a variety of colours, sizes, and finishes, ranging from Indian and imported marble to high-end granite. As a result, we are at the top of the Rajsamand marble suppliers list. The collection includes about 450 different types of Indian marble, stone, granite, and other architectural surfaces from all around the globe.
              <br /><br />
              Gurudev Marble has been on a never-ending journey that has made a lasting impression across India. The company now owns a warehouse that spans 80,000 square feet in Rajsamand, the largest marble producing district in the country.
              <br /><br />
              The company was able to achieve client delight through great quality by following a strategy of sourcing only the finest Indian marble and conducting thorough tests at every level. The strength of the company lies in its commitment to supply best quality products.</p>
          </div>
          <div className="section-spacing"></div>

          <div className='about-us-card'>
            <div className="section-spacing"></div>
            <div className='overlay-theme-2'></div>
            <div className='about-us-cards'>
              <div className='card-container container'>
                <div className="row">

                  {cardData.map((card, index) => (
                    <div className="col-lg-4 col-md-6 col-sm-12" style={{ display: "flex", padding: "10px 20px" }}>
                      <Card
                        key={index}
                        className="custom-card"
                      // cover={<img alt="example" src={card.coverImageUrl} />}

                      >
                        <Card.Meta
                          avatar={<Avatar src={img1} />}
                          title={<span className="card-title">{card.title}</span>}
                          description={<span className="cardDescription">{card.description}</span>}
                        />
                      </Card>
                    </div>

                  ))}
                </div>
              </div>
            </div>

            <div className="section-spacing"></div>
          </div>
          <div className="section-spacing"></div>
          <div className='text-center'>
            <h2 className='Section-title'><span>O</span>ur Factory</h2>
            <br />
          </div>
          <div className='container'>
            <Swiper
              spaceBetween={30}
              freeMode={true}
              loop={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              modules={[Autoplay, Pagination, Navigation]}
              breakpoints={{
                // When window width is <= 640px (for mobile)
                640: {
                  slidesPerView: 2,
                },
                // When window width is <= 1024px (for tablets)
                900: {
                  slidesPerView: 3,
                },
                // Default for larger screens (e.g., PC)
                // Here you can specify more breakpoints if needed
              }}
              className="mySwiper"
            >
              {imageUrls.map((imageUrl, index) => (
                <SwiperSlide key={index} style={{ marginRight: '10px' }}>
                  <img src={imageUrl.images} alt={`Slide ${index}`} style={{ width: '100%', height: 'auto' }} />
                </SwiperSlide>
              ))}
            </Swiper>


          </div>
          <div className="section-spacing"></div>
          <div className="section-spacing"></div>
          <div className="container">
            <div className='author-info'>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 author-img">
                  <img src={img2} style={{ width: "70%" }} alt="" />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className='author-text'>
                  <div>
                    <h2><span>D</span>eepak Kankaria</h2>
                    <hr />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-spacing"></div>
          {/* <div className="section-spacing"></div> */}

        </section>
      </main>
    </>
  )
}
