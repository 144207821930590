import React from 'react';
import "./Footer.css"
import img1 from "./logo.f4e7e3c2.png"
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <section id='Footer'>
        <div className='footer-container'>
          <div>
            <Link to="/">
              <img src={img1} alt="Gurudev-marble-logo" style={{ width: "7rem" }} />
            </Link>

          </div>

        </div>
        <br />

        <div className='row' style={{ display: "flex", justifyContent: "space-between", width: "100%", textAlign: "center" }}>
          <div className="col-lg-6 col-mg-6 col-sm-12" style={{ display: "flex", justifyContent: "center", flexDirection: "column", justifyContent: "center" }}>
            <div className='upper-section'>
              <h5>Reach Us</h5>
              <hr />

            </div>
            <div className='forms-fields'>
              <form action="">
                <div className="input-group">
                  <i class="bi bi-person-fill"></i>
                  <input className='footerSocialLinks' type="text" placeholder='Full Name' />
                </div>
                <div className="input-group">
                  <i class="bi bi-envelope-fill"></i>
                  <input className='footerSocialLinks' type="Email" placeholder='Email' />
                </div>
                <div className="input-group">
                  <i class="bi bi-telephone-fill"></i>
                  <input className='footerSocialLinks' type="tel" placeholder='Mobile' />
                </div>
                <div className="input-group">
                  <i class="bi bi-pencil-fill"></i>
                  <input className='footerSocialLinks' type="text" placeholder='Comments' />
                </div>
                <div className='submit-btn'>
                  <button className='submit footerSocialLinks'><i class="bi bi-send-fill"></i>Send</button>

                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-6 col-mg-6 col-sm-12">
            <div className='upper-section'>
              <h5>Follow Us</h5>
              <hr />
              <div className='row social-icons'>

                <div className='col both'>
                  <div className='mb-4'><a href="https://wa.me/919414174324"><i class="bi bi-whatsapp" target='_blank'></i><span className='footerSocialLinks'>Whatsapp</span></a></div>
                  <div className='mb-4'><a href="https://www.facebook.com/profile.php?id=100076480629410" target='_blank'><i class="bi bi-meta"></i><span className='footerSocialLinks'>Facebook</span></a></div>
                  <div className='mb-4'><a href="https://www.instagram.com/gurudevmarbles/" target='_blank'><i class="bi bi-instagram"></i><span>Instagram</span></a></div>
                </div>
                <div className='col both'>

                  <div className='mb-4'><a href="mailto:gurudevmarbles@gmail.com" target='_blank'><i class="bi bi-envelope-fill"></i><span className='footerSocialLinks'>Email</span></a></div>
                  <div className='mb-4'><a href="https://www.linkedin.com/company/gurudev-marbles/" target='_blank'><i class="bi bi-linkedin"></i><span className='footerSocialLinks'>Linkedin</span></a></div>
                  <div className='mb-4'><a href="tel:919414174324"><i class="bi bi-telephone-outbound-fill"></i><span className='footerSocialLinks'>+91 9414174324</span></a></div>
                </div>
              </div>
              {/* <div className='social-icons'>

                <div className='both'>
                  <div><i class="bi bi-meta"></i><span>Facebook</span></div>
                  <div><i class="bi bi-envelope-fill"></i><span>Email</span></div>
                </div>
                <div className='both' >
                  <div><i class="bi bi-linkedin"></i><span>Linkedin</span></div>
                  <div><i class="bi bi-whatsapp"></i><span>Whatsapp</span></div>
                </div>
                <div className='both'>
                  <div><i class="bi bi-instagram"></i><span>Instagram</span></div>
                  <div><i class="bi bi-telephone-outbound-fill"></i><span>+91 9414174324</span></div>
                </div>
              </div> */}

              {/* <br /> */}
            </div>
          </div>
        </div>

      </section>
      <div style={{ backgroundColor: "black", textAlign: "center", padding: "10px 0px" }}>
        <span className='copyright-text'>© <a href="https://www.outleadsolutions.com/" target='_blank'>OutLead Solutions.</a> All rights reserved</span>
      </div>
    </>
  )
}
