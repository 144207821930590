import './App.css';
import './components/GlobalCSS.css'
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./components/Home/Home";
import Collection from "./components/Collection/Collection";
import VirtualRoom from "./components/Virtual Room/VirtualRoom";
import About from "./components/About/About";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


function App() {
  return (
    <>
      <Router>
        <Header />
        
        <Routes>
          <Route exact path ="/" element={<Home />} />
          <Route exact path ="/collection" element={<Collection />} />
          <Route exact path ="/virtual-room" element={<VirtualRoom />} />
          <Route exact path ="/about" element={<About />} />
        </Routes>

        <Footer />
      </Router>
    </>
  );
}

export default App;
