import React, { useState } from 'react';
import "./Virtual-room.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
// import 'swiper/css/navigation';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Tabs } from 'antd';
// import { Pagination, Navigation, HashNavigation } from 'swiper/modules';

const onChange = (key) => {
  console.log(key);
};

function VirtualRoom() {
  const [selectedBackgroundImagetwo, setselectedBackgroundImagetwo] = useState("")
  const [selectedBackgroundImage, setSelectedBackgroundImage] = useState('');
  const [selectedBackgroundImagethree, setselectedBackgroundImagethree] = useState('');


  const handleSlideClick = (backgroundImageUrl) => {
    setSelectedBackgroundImage(backgroundImageUrl);
  };
  const handleSlideClicktwo = (backgroundImageUrltwo) => {
    setselectedBackgroundImagetwo(backgroundImageUrltwo);
  };
  const handleSlideClickthree = (backgroundImageUrlthree) => {
    setselectedBackgroundImagethree(backgroundImageUrlthree);
  };

  const bedRoomImages = [
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Falaska-gold-bright.jpg?alt=media&token=64f710cf-35ad-42f7-92f0-5550476d3af9",
      title: "Alaska Gold Bright"
    }
    ,
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Falaska-gold.jpg?alt=media&token=f8a0732f-d5b0-412f-9b2e-fdc6d6d7d648",
      title: "Alaska Gold"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Falaska-white.jpg?alt=media&token=fb364b99-d4ac-4b27-8d3a-b8b2c28af53f",
      title: "Alaska White"
    }
    ,
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Falaska-white-flex.jpg?alt=media&token=11905b5c-2e21-484e-bd9c-07f7c06c7657"
      , title: "Alaska White Flex"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fantique-green.jpg?alt=media&token=cd792961-96b2-4610-8270-029221c44aff"
      , title: "Antique Green"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fblack-marquina.jpg?alt=media&token=b0d51461-b3d8-4a27-9164-43e67b6284a2"
      , title: "Black Marquina"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fblack-zebra.jpg?alt=media&token=5ea2a49b-f49d-4357-a12e-8055a78b1ba6"
      , title: "Black Zebra"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fblue-dunes.jpg?alt=media&token=51a1808b-79aa-4006-96b0-0175d547a222"
      , title: "Blue Dunes"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fblue-dunes-premium.jpg?alt=media&token=c9c747d3-26d7-4824-ae4e-157cf0066aba"
      , title: "Blue Dunes Premium"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fblue-night.jpg?alt=media&token=e881eec2-27a0-44fa-991a-1c0bceac767f"
      , title: "Blue Night"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fcoin-black.jpg?alt=media&token=07d2d37b-9e57-4354-aca5-0470b95509f3"
      , title: "Coin Black"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fcrystal-black.jpg?alt=media&token=d73edf51-d37f-4683-80b6-154754103ddf"
      , title: "Crystal Black"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fcrystal-blue.jpg?alt=media&token=c238b010-88b2-410f-90fe-88aea0cf0593"
      , title: "Crystal Blue"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fcrystal-brown.jpg?alt=media&token=b0106cfb-72f5-4cb1-a7fa-bbb3fe64e9bc"
      , title: "Crystal Brown"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fcrystal-pink.jpg?alt=media&token=7e90a0ae-0cd9-4f4b-bd54-81fe998ca561"
      , title: "Crystal Pink"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fcrystal-red.jpg?alt=media&token=ee93aaae-4178-47bb-8afd-bfd16cd7451d"
      , title: "Crystal Red"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fcrystal-yellow.jpg?alt=media&token=5dee8044-58c8-4c2a-8b11-46a437e83ae2"
      , title: "Crystal Red"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fdesert-pink.jpg?alt=media&token=d88591ef-739e-4e6a-8676-613c16080a32"
      , title: "Desert Pink"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Ffantasy-black.jpg?alt=media&token=96476456-c7ad-4006-b1a1-8f0de73db6ee"
      , title: "Fantasy Black"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Ffire-red.jpg?alt=media&token=55de0660-d1b4-4b22-9ab8-0a89e0433ce0"
      , title: "Fire Red"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Ffish-black.jpg?alt=media&token=efb6e90c-9c6c-49bb-badf-46ffdb83edbf"
      , title: "Fish Black"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fgreen-pearl.jpg?alt=media&token=0866b691-ed25-4281-9fb6-092ead324060"
      , title: "Green Pearl"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fjhansi-red.jpg?alt=media&token=5e5217ff-9653-41d2-867e-782a68314e67"
      , title: "Jhansi Red"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Flakha-red.jpg?alt=media&token=a98faa06-bcba-4648-a866-a597d36cb419"
      , title: "Lakha Red"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fmoon-white.jpg?alt=media&token=c07e7eed-9e04-4cbf-bcf6-96b72379ea7d"
      , title: "Moon White"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fmulti-red.jpg?alt=media&token=8b43b21d-104f-43f3-8dfd-63147eaca56d"
      , title: "Multi Red"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fpink-pearl.jpg?alt=media&token=fa00225b-547a-4b98-9cb7-8247fad64438"
      , title: "Pink Pearl"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fr-black.jpg?alt=media&token=efabf699-07a5-453a-8595-384d88538a56"
      , title: "R Black"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Froyal-brown.jpg?alt=media&token=f743e3a4-fdd9-4d4a-9d50-e2431c506fd3"
      , title: "Royal Brown"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fruby-red.jpg?alt=media&token=5dd10348-1457-44b9-bd61-c53830dfaf34"
      , title: "Ruby Red"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fsavannah-white.jpg?alt=media&token=ed3c8475-1410-4b30-b2b7-3c67af810be7"
      , title: "Savannah White"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FGranite%2Fabsolute-black.jpg?alt=media&token=ac4fd0bc-c6cb-4e48-adbb-2fb4e2c66cf7"
      , title: "Absolute Black"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FMarbles%2Fbrown-marquina.jpg?alt=media&token=edf6c69a-eb50-4a80-873b-4a7fda973301"
      , title: "Brown Marquina"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FMarbles%2Fcappuccino.jpg?alt=media&token=3d4fea3e-d701-4e2a-a9a9-fe6aed207ff1"
      , title: "Cappuccino"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FMarbles%2Fdesert-brown.jpg?alt=media&token=c1aab008-576a-4543-ac64-5539ed0b5266"
      , title: "Desert Brown"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FMarbles%2Fdolsey.jpg?alt=media&token=1f50dad7-95d2-4aa8-81b4-7895b54aa8de"
      , title: "Dolsey"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FMarbles%2Fgrafitto.jpg?alt=media&token=23ec7de6-930b-46b6-a83e-b6b0d3c240d2"
      , title: "Graphitto"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FMarbles%2Fgreen-marble.jpg?alt=media&token=aee95c8a-d854-43b5-bc45-66aefb4fbc07"
      , title: "Green Marble"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FMarbles%2Fcarrara.jpg?alt=media&token=64de87ab-97d2-48fa-bbc6-366961d7933e"
      , title: "Carrara"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FMarbles%2Fonyx.jpg?alt=media&token=50a6e260-0812-4680-951b-88a4cc874510"
      , title: "Onyx"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FMarbles%2Fpalissandro.jpg?alt=media&token=ca651b3d-7107-4051-9378-2e204b74c661"
      , title: "Palissandro"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FMarbles%2Fpanda-white.jpg?alt=media&token=7245490b-8dda-4314-a6a1-e949b300b6d1"
      , title: "Panda White"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FMarbles%2Fmatrix.jpg?alt=media&token=8f179736-c5a7-461d-b1db-a88c6e9ae82c"
      , title: "Matrix"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FMarbles%2Fnizarna.jpg?alt=media&token=300af590-638e-4cc0-9a58-80fe02ef466f"
      , title: "Nazarna"
    },
    {
      images: "https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/collections%2FMarbles%2Fpink.jpg?alt=media&token=d1c50f46-21a3-421f-a4f5-f0173a9928fd"
      , title: "Pink"
    },

    // Add URLs of other demo images here
  ];
  const items = [
    {
      key: '1',
      label:
        <>
          <h2 className='tab-title'>Bedroom</h2>
        </>,
      children:
        <>
          <section id='virtual-room-container' className='pt-2 mb-5'>
            <div className='container p-0'>
              <div className="row">
                <div className="col-lg-8">
                  <div id='virtual-view-container'>
                    <div style={{ backgroundImage: `url(${selectedBackgroundImage})`,  backgroundSize: "100%" }}>
                      <img className='w-100' src="https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/virtual-room%2Fbedroom1.png?alt=media&token=dbbe18fe-d120-4443-ac16-099946445711" alt="" />
                    </div>
                    <div style={{ backgroundImage: `url(${selectedBackgroundImagetwo})`, backgroundSize: "100%" }}>
                      <img className='w-100' src="https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/virtual-room%2Fbedroom2.png?alt=media&token=b10bd0c0-6a8b-42a4-88a8-b1993950f467" alt="" />
                    </div>


                  </div>
                </div>


                {/* <div className="slite-space"></div> */}

                <div className="col-lg-4">
                  <div className='swiper-slide-view' style={{ overflow: "auto" }}>
                    <h2 className='slide-boxex-heading'>Wall View</h2>

                    <Swiper
                      slidesPerView={3}
                      spaceBetween={10}

                      className="mySwiper"
                      breakpoints={{

                        768: {
                          slidesPerView: 3,
                        },

                        320: {
                          slidesPerView: 3,
                        }
                      }}

                      style={{ width: "100%", height: "100%", overflow: "auto" }} // Adjust height as needed
                    >

                      {bedRoomImages.map((image, index) => (
                        <SwiperSlide key={index} onClick={() => handleSlideClick(image.images)}>
                          <div style={{
                            backgroundImage: `url(${image.images})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            // borderRadius: '5px',
                            height: '50px',
                            position: 'relative',
                            cursor: "pointer"
                          }}>

                          </div>
                          <div style={{ display: "flex", justifyContent: "center" }}>
                            <span className="img-title"
                            >
                              {image.title}
                            </span>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>

                  <div className='swiper-slide-view' style={{ overflow: "auto" }}>

                    <hr />
                    <h2 className='slide-boxex-heading'>FLoor View</h2>
                    <Swiper
                      slidesPerView={3}
                      spaceBetween={10}

                      className="mySwiper"
                      breakpoints={{

                        768: {
                          slidesPerView: 3,
                        },

                        320: {
                          slidesPerView: 3,
                        }
                      }}

                      style={{ width: "100%", height: "100%", overflow: "auto" }} // Adjust height as needed
                    >

                      {bedRoomImages.map((image, index) => (
                        <SwiperSlide key={index} onClick={() => handleSlideClicktwo(image.images)}>
                          <div style={{
                            backgroundImage: `url(${image.images})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            // borderRadius: '5px',
                            height: '50px',
                            position: 'relative',
                            cursor: "pointer"
                          }}>

                          </div>
                          <div style={{ display: "flex", justifyContent: "center" }}>
                            <span className="img-title"
                            >
                              {image.title}
                            </span>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>

                </div>
              </div>
            </div>
          </section>
        </>
    },
    {
      key: '2',
      label:
        <>
          <h2 className='tab-title'>Bathroom</h2>
        </>,
      children:
        <>
          <section id='virtual-room-container' className='pt-2 mb-5'>
            <div className='container'>
              <div className="row">
                <div className="col-lg-8">
                  <div id='virtual-view-container'>
                    <div style={{ backgroundImage: `url(${selectedBackgroundImage})`,  backgroundSize: "100%" }}>
                      <img className='w-100' src="https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/virtual-room%2Fbathroom1.png?alt=media&token=f84d288b-f8ea-4d0e-b0a1-3273036f796a" alt="" />
                    </div>
                    <div style={{ backgroundImage: `url(${selectedBackgroundImagetwo})`, backgroundSize: "100%" }}>
                      <img className='w-100' src="https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/virtual-room%2Fbathroom2.png?alt=media&token=d181c530-d55e-4e8a-972c-f4162440102b" alt="" />
                    </div>


                  </div>
                </div>


                {/* <div className="slite-space"></div> */}

                <div className="col-lg-4">
                  <div className='swiper-slide-view' style={{ overflow: "auto" }}>
                    <h2 className='slide-boxex-heading'>Wall View</h2>

                    <Swiper
                      slidesPerView={3}
                      spaceBetween={10}

                      className="mySwiper"
                      breakpoints={{
                        768: {
                          slidesPerView: 3,
                        },

                        320: {
                          slidesPerView: 3,
                        }
                      }}

                      style={{ width: "100%", height: "100%", overflow: "auto" }} // Adjust height as needed
                    >

                      {bedRoomImages.map((image, index) => (
                        <SwiperSlide key={index} onClick={() => handleSlideClick(image.images)}>
                          <div style={{
                            backgroundImage: `url(${image.images})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            // borderRadius: '5px',
                            height: '50px',
                            position: 'relative',
                            cursor: "pointer"
                          }}>

                          </div>
                          <div style={{ display: "flex", justifyContent: "center" }}>
                            <span className="img-title"
                            >
                              {image.title}
                            </span>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>

                  <div className='swiper-slide-view' style={{ overflow: "auto" }}>

                    <hr />
                    <h2 className='slide-boxex-heading'>FLoor View</h2>
                    <Swiper
                      slidesPerView={3}
                      spaceBetween={10}

                      className="mySwiper"
                      breakpoints={{

                        // 1280: {
                        //   slidesPerView: 3,
                        // },
                        768: {
                          slidesPerView: 3,
                        },

                        320: {
                          slidesPerView: 3,
                        }
                      }}

                      style={{ width: "100%", height: "100%", overflow: "auto" }} // Adjust height as needed
                    >

                      {bedRoomImages.map((image, index) => (
                        <SwiperSlide key={index} onClick={() => handleSlideClicktwo(image.images)}>
                          <div style={{
                            backgroundImage: `url(${image.images})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            // borderRadius: '5px',
                            height: '50px',
                            position: 'relative',
                            cursor: "pointer"
                          }}>

                          </div>
                          <div style={{ display: "flex", justifyContent: "center" }}>
                            <span className="img-title"
                            >
                              {image.title}
                            </span>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>,
    },
    {
      key: '3',
      label:
        <>
          <h2 className='tab-title'>Kid's Room</h2>
        </>,
      children:

        <>
          <section id='virtual-room-container' className='pt-2 mb-5'>
            <div className='container'>
              <div className="row">
                <div className="col-lg-8">
                  <div id='virtual-view-container'>
                    <div style={{ backgroundImage: `url(${selectedBackgroundImage})`, backgroundSize: "100%" }}>
                      <img className='w-100' src="https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/virtual-room%2Fkidroom1.png?alt=media&token=6f3f0005-d7ca-46d5-a41f-9c5f1fda9eec" alt="" />
                    </div>
                    <div style={{ backgroundImage: `url(${selectedBackgroundImagetwo})`, backgroundSize: "100%" }}>
                      <img className='w-100' src="https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/virtual-room%2Fkidroom2.png?alt=media&token=8d5da484-3020-44d5-8011-967d16620317" alt="" />
                    </div>


                  </div>
                </div>


                {/* <div className="slite-space"></div> */}

                <div className="col-lg-4">
                  <div className='swiper-slide-view' style={{ overflow: "auto" }}>
                    <h2 className='slide-boxex-heading'>Wall View</h2>

                    <Swiper
                      slidesPerView={3}
                      spaceBetween={10}

                      className="mySwiper"
                      breakpoints={{

                        768: {
                          slidesPerView: 3,
                        },

                        320: {
                          slidesPerView: 3,
                        }
                      }}

                      style={{ width: "100%", height: "100%", overflow: "auto" }} // Adjust height as needed
                    >

                      {bedRoomImages.map((image, index) => (
                        <SwiperSlide key={index} onClick={() => handleSlideClick(image.images)}>
                          <div style={{
                            backgroundImage: `url(${image.images})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            // borderRadius: '5px',
                            height: '50px',
                            position: 'relative',
                            cursor: "pointer"
                          }}>

                          </div>
                          <div style={{ display: "flex", justifyContent: "center" }}>
                            <span className="img-title"
                            >
                              {image.title}
                            </span>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>

                  <div className='swiper-slide-view' style={{ overflow: "auto" }}>

                    <hr />
                    <h2 className='slide-boxex-heading'>FLoor View</h2>
                    <Swiper
                      slidesPerView={3}
                      spaceBetween={10}

                      className="mySwiper"
                      breakpoints={{

                        768: {
                          slidesPerView: 3,
                        },

                        320: {
                          slidesPerView: 3,
                        }
                      }}

                      style={{ width: "100%", height: "100%", overflow: "auto" }} // Adjust height as needed
                    >

                      {bedRoomImages.map((image, index) => (
                        <SwiperSlide key={index} onClick={() => handleSlideClicktwo(image.images)}>
                          <div style={{
                            backgroundImage: `url(${image.images})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            // borderRadius: '5px',
                            height: '50px',
                            position: 'relative',
                            cursor: "pointer"
                          }}>

                          </div>
                          <div style={{ display: "flex", justifyContent: "center" }}>
                            <span className="img-title"
                            >
                              {image.title}
                            </span>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>,
    },
    {
      key: '4',
      label:
        <>
          <h2 className='tab-title'>Kitchen</h2>
        </>,
      children:

        <>
          <section id='virtual-room-container' className='pt-2 mb-5'>
            <div className='container'>
              <div className="row">
                <div className="col-lg-8">
                  <div id='virtual-view-container'>
                    <div style={{ backgroundImage: `url(${selectedBackgroundImage})`,  backgroundSize: "100%" }}>
                      <img className='w-100' src="https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/virtual-room%2Fkitchen1.png?alt=media&token=2dcd891c-bca3-415e-8b2f-c6b7fc8494ca" alt="" />
                    </div>
                    <div style={{ backgroundImage: `url(${selectedBackgroundImagetwo})`, backgroundSize: "100%" }}>
                      <img className='w-100' src="https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/virtual-room%2Fkitchen2.png?alt=media&token=91ee9d13-22b8-4125-aa75-3dcb6cb9ceaf" alt="" />
                    </div>
                    <div className='d-flex' style={{ marginTop: "-1px" }}>
                      <div style={{ backgroundImage: `url(${selectedBackgroundImagethree})`, backgroundSize: "100%" }}>
                        <img className='w-100' src="https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/virtual-room%2Fkitchen3.png?alt=media&token=62f8015a-ac8f-4f2c-8a7f-aa15659678fb" alt="" />
                      </div>
                      <div style={{ backgroundImage: `url(${selectedBackgroundImagetwo})`, backgroundSize: "100%" }}>
                        <img className='w-100' src="https://firebasestorage.googleapis.com/v0/b/gurudev-marbles.appspot.com/o/virtual-room%2Fkitchen4.png?alt=media&token=6a0c7c9f-71c9-42db-bb52-8f6a346ad504" alt="" />
                      </div>
                    </div>


                  </div>
                </div>
                <div className="col-lg-4">



                  {/* <div className="slite-space"></div> */}


                  <div className='swiper-slide-view' style={{ overflow: "auto" }}>
                    <h2 className='slide-boxex-heading'>Wall View</h2>

                    <Swiper
                      slidesPerView={3}
                      spaceBetween={10}

                      className="mySwiper"
                      breakpoints={{

                        768: {
                          slidesPerView: 3,
                        },

                        320: {
                          slidesPerView: 3,
                        }
                      }}

                      style={{ width: "100%", height: "100%", overflow: "auto" }} // Adjust height as needed
                    >

                      {bedRoomImages.map((image, index) => (
                        <SwiperSlide key={index} onClick={() => handleSlideClick(image.images)}>
                          <div style={{
                            backgroundImage: `url(${image.images})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            // borderRadius: '5px',
                            height: '50px',
                            position: 'relative',
                            cursor: "pointer"
                          }}>

                          </div>
                          <div style={{ display: "flex", justifyContent: "center" }}>
                            <span className="img-title"
                            >
                              {image.title}
                            </span>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>

                  <div className='swiper-slide-view' style={{ overflow: "auto" }}>

                    <hr />
                    <h2 className='slide-boxex-heading'>Counter Top View</h2>
                    <Swiper
                      slidesPerView={3}
                      spaceBetween={10}

                      className="mySwiper"
                      breakpoints={{

                        768: {
                          slidesPerView: 3,
                        },

                        320: {
                          slidesPerView: 3,
                        }
                      }}

                      style={{ width: "100%", height: "100%", overflow: "auto" }} // Adjust height as needed
                    >

                      {bedRoomImages.map((image, index) => (
                        <SwiperSlide key={index} onClick={() => handleSlideClicktwo(image.images)}>
                          <div style={{
                            backgroundImage: `url(${image.images})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            // borderRadius: '5px',
                            height: '50px',
                            position: 'relative',
                            cursor: "pointer"
                          }}>

                          </div>
                          <div style={{ display: "flex", justifyContent: "center" }}>
                            <span className="img-title"
                            >
                              {image.title}
                            </span>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                  <div className='swiper-slide-view' style={{ overflow: "auto" }}>

                    <hr />
                    <h2 className='slide-boxex-heading'>Floor View</h2>
                    <Swiper
                      slidesPerView={3}
                      spaceBetween={10}

                      className="mySwiper"
                      breakpoints={{

                        768: {
                          slidesPerView: 3,
                        },

                        320: {
                          slidesPerView: 3,
                        }
                      }}

                      style={{ width: "100%", height: "100%", overflow: "auto" }} // Adjust height as needed
                    >

                      {bedRoomImages.map((image, index) => (
                        <SwiperSlide key={index} onClick={() => handleSlideClickthree(image.images)}>
                          <div style={{
                            backgroundImage: `url(${image.images})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            // borderRadius: '5px',
                            height: '50px',
                            position: 'relative',
                            cursor: "pointer"
                          }}>

                          </div>
                          <div style={{ display: "flex", justifyContent: "center" }}>
                            <span className="img-title"
                            >
                              {image.title}
                            </span>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>,
    },
  ];
  return (
    <>
      <section id='aboutus-top-img'>
        <div className='top-banner'>
          <div className='overlay-theme'></div>
          <img src="https://images.unsplash.com/photo-1582683522098-72ad7b0883a2?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" style={{ width: "100%" }} alt="" />
        </div>
        <div className='overlay-text'>
          <h1>Virtual Room</h1>
        </div>
      </section>
      <div className="container">
        <div className="slite-space"></div>
        <div className="slite-space"></div>
        <div className='top-content text-center'>
          <h1 className='section-title'>What's <span>Virtual Room ?</span></h1>
          <p>Bring your imagination to life! Try our Virtual Room feature to see how our marbles will bring your accommodation to life.
          </p>
          <p>With our Virtual Room feature, you can try over 250+ different marble palettes and customise them as per your liking.</p>
          <p>Imagine the presence of our superior marble collection by applying different textures and colors on wall, counter tops and flooring to enhance your living space.</p>

        </div>
      </div>
      <div className="slite-space"></div>
      <div className="slite-space"></div>
      <div className="container">
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} style={{ border: "none" }} />
      </div>

    </>
  )
}

export default VirtualRoom;












