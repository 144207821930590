import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import "./Header.css"
import img1 from "./logo.f4e7e3c2.png"



export default function Header() {
    const [isSticky, setIsSticky] = useState(false);
    const [expanded, setExpanded] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // adds smooth scrolling
        });
    };

    const handleSelect = () => {
        setExpanded(false); // Collapse the navbar when a link is clicked
        // document.body.scrollTop = 0;
        // document.documentElement.scrollTop = 0;
    };

    const handleSelectAndScroll = () => {
        handleSelect();
        scrollToTop();
    };

    useEffect(() => {
        const handleScroll = () => {
            setIsSticky(window.pageYOffset > 30);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <>

            <nav className={`navbar navbar-expand-lg ${isSticky ? 'sticky' : ''}`} expanded={expanded}>
                <div className="container">
                    <Link className="navbar-brand" to="/" style={{width:"20%"}}>
                        <img src={img1} alt="Gurudev-marble-logo" />
                    </Link>
                    <button
                        className="navbar-toggler"
                        type="button"
                        onClick={() => setExpanded(!expanded)}
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon" />
                    </button>
                    <div className={`collapse navbar-collapse ${expanded ? 'show' : ''}`} id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link active" aria-current="page" to="/" onClick={handleSelectAndScroll} >
                                    Home
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/collection" onClick={handleSelectAndScroll}>
                                    Collection
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/virtual-room " onClick={handleSelectAndScroll}>
                                    Virtual Room
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/about" onClick={handleSelectAndScroll}>
                                    About Us
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>


        </>
    )
}
