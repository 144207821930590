const cardData = [
    {
      title: 'Promise To Our Customers',
      description: 'Our clients are important to us, and we cherish and care for them. We will earn the right to be the preferred supplier of our clients. We will keep our word to our customers.',
      // coverImageUrl: 'https://images.unsplash.com/photo-1599600540907-62e9b06e6597?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    {
      title: 'Our Approach',
      description: 'We strive to fulfil our customer promise by involving all employees and forming a business partnership that fosters pride in our products Our motto is to use our experience to meet the demands of each customer by providing high-quality products and services at reasonable prices.',
      // coverImageUrl: 'https://images.unsplash.com/photo-1599600540907-62e9b06e6597?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    {
      title: 'Our Ethos',
      description: 'At Gurudev Marble, we go above and beyond to satisfy the requirements and expectations of our customers and employees, whether its bringing the highest quality products, giving the greatest services, or creating a welcoming work environment.',
      // coverImageUrl: 'https://images.unsplash.com/photo-1599600540907-62e9b06e6597?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    
  ];

  
export default cardData;